import React, { useState, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import { Button, TextField, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/system';
import "./App.css"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

interface Message {
  content: string;
  role: string;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // ここで色を指定
    },
    secondary: {
      main: '#dc004e', // ここで色を指定
    },
  },
});

const Root = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBlock: '1rem',
});

const Messages = styled(List)({
  height: 'auto',
  width: '100%',
  overflowY: 'auto',
  marginBottom: '1rem',
  padding: '1rem',
  backgroundColor: '#93AAD4',
});

const MessageItem = styled(ListItem)(({ theme, role }) => ({
  flexDirection: 'column',
  '& .MuiListItemText-root': {
    maxWidth: '70%',
    wordWrap: 'break-word',
    '& .MuiListItemText-primary': {
      borderRadius: 8,
      padding: theme.spacing(2, 2),
      color: '#fff',
      display: 'inline-block',
      marginBottom: theme.spacing(0.5),
    },
    '& .MuiListItemText-secondary': {
      fontSize: '0.75rem',
      textAlign: role === 'assistant' ? 'left' : 'right',
    },
  },
}));

const InputArea = styled('div')({
  display: 'flex',
  width: '100%',
});

const Input = styled(TextField)({
  flex: 1,
  marginInline: '1rem',
});


const App: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>(JSON.parse(localStorage.getItem('chatMessages') || '[]'));
  const [input, setInput] = useState<string>('');

  const sendMessage = async () => {
    if (input.trim() !== '') {
      console.log(`input: ${input}`);
      const newMessages = [...messages, { content: input, role: 'user' }];
      console.log(`newMessages: ` + JSON.stringify(newMessages));
      setMessages(newMessages);

      const response = await fetch('https://vm-app-workers.sa1p.workers.dev/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newMessages)
      });

      const aiMessage = await response.text();

      setMessages(prevMessages => [...prevMessages, { content: aiMessage, role: 'assistant' }]);

      setInput('');
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  const resetChat = () => {
    setMessages([]);
    localStorage.removeItem('chatMessages');
  };

  useEffect(() => {
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  }, [messages]);

  return (
    <Root>
      <ThemeProvider theme={theme}>
        <Typography variant="h4" gutterBottom>Chat App</Typography>
        <Messages>
          {messages.map((message, index) => (
            <MessageItem key={index} className={message.role}>
              <ListItemText primary={message.content} />
            </MessageItem>
          ))}
        </Messages>
        <div style={{ textAlign: 'center', width: '100%', maxWidth: '1200px', paddingInline: '16px' }}>
          <InputArea>
            <Button variant="contained" color="secondary" onClick={resetChat}>
              <DeleteForeverIcon />
            </Button>
            <Input
              variant="outlined"
              value={input}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
            />
            <Button variant="contained" color="primary" onClick={sendMessage}>
              送信
            </Button>
          </InputArea>
          <Typography variant="caption" color={'gray'} gutterBottom>※GPT-3.5 APIを利用しています。使いすぎにはご注意ください。</Typography>
        </div>
      </ThemeProvider>
    </Root>
  );
}


export default App;
